import { autorun, computed, makeAutoObservable, runInAction, toJS } from 'mobx'
import {
  EmployeePreorderClotheDetail,
  EmployeePreorderClotheStandardNumTag,
} from './customer-preorder-clothe.api'
import * as requests from './customer-preorder-clothe.request'
import { PreorderClotheTagModalOptions } from '../../pages/employee-preorder-clothe/preorder-clothe-tag-modal'
import { BangBangTangRequest } from '../../utils/request'
import { store } from '../store.root'
import { EmployeePreorderClotheListDataInterface } from '../employee-preorder-clothe-list/employee-preorder-clothe-list.interface'
import { Loading, Modal, Toast, ActivityIndicator } from 'zarm'
import { debounceTime, delay, finalize } from 'rxjs/operators'
import { toStream } from 'mobx-utils'
import { from } from 'rxjs'
import * as _ from 'lodash'
export interface EmployeePreorderClotheFlattedDataInterface {
  id: number
  clothingimages: string
  tags: number[]
}
const PHONE_RE = /^1[3456789]\d{9}$/

// noinspection DuplicatedCode
export class CustomerPreorderClotheStore {
  private _data: EmployeePreorderClotheDetail[] | null = null
  __data: EmployeePreorderClotheDetail[] = []
  current_displayed: number = 14
  loading: boolean = true
  popup_visible: boolean = false
  photo_modal_visible: boolean = false
  share_modal_visible: boolean = false
  show_share_notice: boolean = true
  show_message_notic: string = ''

  expired_time: number | null = null
  gender_selected: Set<number> = new Set<number>()
  valid_gender_options: Set<number> = new Set<number>()

  lockStandardId: number[] = []

  user_standard_num_tag: EmployeePreorderClotheStandardNumTag[] = [] // 新的tag
  user_select_index: number = 0 // 用户选择的索引 默认为0

  // 性别
  toggle_gender_selected(value: number) {
    if (this.gender_selected.has(value)) {
      this.gender_selected.delete(value)
    } else {
      this.gender_selected.add(value)
    }
  }

  region_selected: Set<number> = new Set<number>()
  valid_region_options: Set<number> = new Set<number>()
  // valid_region_options: Set<number> = new Set<number>();
  // 区域
  toggle_clothe_region_selected(value: number) {
    if (this.region_selected.has(value)) {
      this.region_selected.delete(value)
    } else {
      this.region_selected.add(value)
    }
  }

  clothe_type_selected: Set<number> = new Set<number>()
  valid_clothe_type_options: Set<number> = new Set<number>()
  valid_type_options: Set<number> = new Set<number>()
  // 尺码
  toggle_clothe_type_selected(value: number) {
    if (this.clothe_type_selected.has(value)) {
      this.clothe_type_selected.delete(value)
    } else {
      this.clothe_type_selected.add(value)
    }
  }

  type_selected: Set<number> = new Set<number>()
  // 年龄
  toggle_type_selected(value: number) {
    if (this.type_selected.has(value)) {
      this.type_selected.delete(value)
    } else {
      this.type_selected.add(value)
    }
  }

  color_type_selected: Set<number> = new Set<number>()
  valid_color_type_options: Set<number> = new Set<number>()
  // 色系
  toggle_color_type_selected(value: number) {
    if (this.color_type_selected.has(value)) {
      this.color_type_selected.delete(value)
    } else {
      this.color_type_selected.add(value)
    }
  }

  valid_style_type_options: Set<number> = new Set<number>()
  color_style_selected: Set<number> = new Set<number>()
  // 风格
  toggle_style_type_selected(value: number) {
    if (this.color_style_selected.has(value)) {
      this.color_style_selected.delete(value)
    } else {
      this.color_style_selected.add(value)
    }
  }
  detail: any = null
  selection_standard_ids: string = ''
  mobile: string = ''
  customer_name: string = ''
  limit: string = ''
  preorder_id: number | null = null
  selected_photos: Set<number> = new Set<number>()
  selected_photos_repeat: number[] = []
  show_large: number | null = null
  toggle_show_large(value: number | null) {
    if (value) {
      const f = this.data
        .find((v) => v.id === value)
        ?.tags.find((v) => v.name === '尺码')
      if (f) {
        // Toast.show(
        //   `当前服装尺码是${f.type
        //     .map((v) => v.name)
        //     .join(",")},左右滑动查看更多,点击图片关闭预览`
        // );
        this.show_message_notic = `当前服装尺码是${f.type
          .map((v) => v.name)
          .join(',')},左右滑动查看更多,点击图片关闭预览`
      } else {
        this.show_message_notic = ''
        // Toast.show(`左右滑动查看更多,点击图片关闭预览`);
      }
    }
    this.show_large = value
  }
  @computed get selected_photos_ids(): number[] {
    return this.selected_photos_flatten.map((value) => value.id)
  }
  @computed get selected_photos_flatten(): { id: number; pic: string[] }[] {
    return Array.from(this.selected_photos).map((value) => ({
      id: value,
      pic: this._data?.find((v) => v.id === value)?.clothingimages || [],
    }))
  }
  toggle_share_notice(value: boolean) {
    this.show_share_notice = value
  }

  @computed get form_data_is_valid(): boolean {
    return !!(
      this.customer_name.length > 0 &&
      this.mobile.match(PHONE_RE) &&
      parseInt(this.limit, 10) > 0
    )
  }
  set_preorder_id(value: number | null) {
    const stoe_id =
      localStorage.getItem('store_id') || store.storeInfoStore.value
    let url = ''
    if (!stoe_id) {
      url = `/wap/clothing/getUserSelectionDetail?token=${store.userStore.token}&selection_id=${value}`
    } else {
      url = `/wap/clothing/getUserSelectionDetail?token=${store.userStore.token}&selection_id=${value}&store_id=${stoe_id}`
    }
    // 设置预约单id,会从服务器获取预约单的其他信息
    this.preorder_id = value
    this.loading = true
    BangBangTangRequest<EmployeePreorderClotheListDataInterface>({
      url,
      data: {},
      method: 'GET',
    }).subscribe(
      (res) => {
        if (res.status === 'cancel') {
          Toast.show('该预选单已经取消')
          store.routerStore.replace('/build')
        } else {
          runInAction(() => {
            // 如果为数组则代表什么都没有
            if (res instanceof Array) return
            this.loading = false
            this.detail = res
            this.selection_standard_ids = res.selection_standard_ids
            this.preorder_id = res.id
            this.mobile = res.mobile
            this.customer_name = res.username
            this.expired_time = res.expire_time
            this.lockStandardId = res.lockStandardId
            res.selection_standard_ids
              .split(',')
              .filter((value) => !!value)
              .map((value) => parseInt(value, 10))
              .forEach((value) => this.selected_photos.add(value))
            this.type_selected.clear()
            this.color_style_selected.clear()
            this.gender_selected.clear()
            this.region_selected.clear()
            this.color_type_selected.clear()
            this.clothe_type_selected.clear()

            /// 年龄
            res.age_type_id_arr.forEach((v) => {
              this.valid_type_options.add(v)
            })
            // 性别
            res.gender_type_id_arr.forEach((v) =>
              this.valid_gender_options.add(v)
            )
            // 用户可见区域
            res.special_type_id_arr.forEach((v) =>
              this.valid_region_options.add(v)
            )
            // 尺码
            res.size_type_id_arr?.forEach((v) =>
              this.valid_clothe_type_options.add(v)
            )
            // 色系
            res.color_type_id_arr?.forEach((v) =>
              this.valid_color_type_options.add(v)
            )
            // 风格
            res.style_type_id_arr?.forEach((v) =>
              this.valid_style_type_options.add(v)
            )
            // if (this.selected_photos.size > 0) {
            //   runInAction(() => (this.photo_modal_visible = true))
            // }
            // 回显用户的套餐
            if (res.standard_num_tag.length > 0) {
              this.limit = res.standard_num_tag[0].standard_num
            }
            this.user_standard_num_tag = res.standard_num_tag.map((v) => {
              return {
                ...v,
                user_select_ids: (v.standard_ids || '')
                  .split(',')
                  .map((v) => parseInt(v))
                  .filter((v) => !!v),
              }
            })
            // 如果用户没选择则弹窗显示让用户选
            this.photo_modal_visible = true
            // if (!res.standard_num_tag.map((v) => v.standard_ids).join('')) {
            //   this.photo_modal_visible = true
            // }
          })
        }
      },
      () => store.routerStore.replace('/build')
    )
  }
  add_selected_photo(id: number) {
    this.selected_photos.add(id)
  }
  remove_selected_photo(id: number) {
    this.selected_photos.delete(id)
    if (this.selected_photos.size === 0) {
      this.photo_modal_visible = false
    }
  }

  submit_loading: boolean = false
  toggle_popup_visible(value: boolean) {
    this.popup_visible = value
  }
  toggle_photo_modal_visible(value: boolean) {
    if (this.selected_photos.size === 0) {
    } else {
      this.photo_modal_visible = value
    }
  }
  toggle_photo_modal_status(value: boolean) {
    this.photo_modal_visible = value
  }
  is_images_user_select(id: number): boolean {
    if (
      this.user_standard_num_tag.length > 0 &&
      this.user_standard_num_tag.length >= this.user_select_index
    ) {
      // 去除其他标签中选择的服装
      const d = this.user_standard_num_tag
        .map((v) => v.user_select_ids)
        .flat()
        .filter((v) => v)

      return (
        this.user_standard_num_tag[
          this.user_select_index
        ].user_select_ids.indexOf(id) > -1 || d.indexOf(id) > -1
      )
    }
    return false
  }
  // 按照id查服装
  images_id_find_clothe_info(
    id: number
  ): EmployeePreorderClotheDetail | undefined {
    return this._data?.find((v) => v.id === id)
  }
  // 去除用户选择的服装
  user_images_id_close_clothe(index: number, id: number) {
    const item = this.user_standard_num_tag
    if (item.length > 0 && item.length >= index) {
      this.user_standard_num_tag[index].user_select_ids = item[
        index
      ].user_select_ids.filter((v) => v !== id)
    }
  }
  // 当前用户所选
  @computed get userClothesSize(): number {
    return this.user_standard_num_tag
      .map((v) => v.user_select_ids)
      .reduce(
        (accumulator, currentValue) => accumulator + currentValue.length,
        0
      )
  }
  // 当前可选合计
  @computed get userClothesOptionalSize(): number {
    return this.user_standard_num_tag
      .map((v) => parseInt(v.standard_num))
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
  }
  @computed get type_clothes(): { tag_id: number; data_id: number[] }[] {
    let types: { tag_id: number; data_id: number[] }[] = []
    this.options.forEach((option) => {
      option.types.forEach((type) =>
        types.push({ tag_id: type.id, data_id: [] })
      )
    })
    if (this._data) {
      this._data.forEach((data) => {
        data.tags.forEach((tag) =>
          tag.type.forEach((type) => {
            types.find((v) => v.tag_id === type.id)?.data_id.push(data.id)
          })
        )
      })
    }
    return types
  }
  @computed get options_tag(): PreorderClotheTagModalOptions {
    const user_select_index =
      store.customerPreorderClotheStore.user_select_index
    const user_standard_num_tag =
      store.customerPreorderClotheStore.user_standard_num_tag
    if (
      user_standard_num_tag.length > 0 &&
      user_standard_num_tag.length >= user_select_index
    ) {
      const item = user_standard_num_tag[user_select_index]
      const clothe_id = item.standard_tag.split(',').map((v) => parseInt(v))
      return this.options.map((v) => {
        return {
          ...v,
          types: v.types.filter((s) => {
            if (clothe_id.indexOf(s.id) > -1) {
              return s
            }
          }),
        }
      })
    }
    return []
  }
  @computed get options(): PreorderClotheTagModalOptions {
    let type_obj: { id: number; name: string; is_show: 'yes' | 'no' }[] = []
    let tag_obj: {
      id: number
      name: string
      is_user_search: 'yes' | 'no'
      selection_is_required: 'yes' | 'no'
      type: Set<number>
    }[] = []
    if (this._data && Object.keys(toJS(this.detail) || {}).length > 0) {
      this._data.forEach((value) => {
        value.tags.forEach((tag) => {
          tag.type.forEach((type) => {
            if (type_obj.find((v) => v.id === type.id)) {
            } else {
              type_obj.push({
                id: type.id,
                name: type.name,
                is_show: type.is_show,
              })
            }
          })
          const i = tag_obj.find((v) => v.id === tag.id)
          if (i) {
            tag.type.forEach((v) => i.type.add(v.id))
          } else {
            tag_obj.push({
              id: tag.id,
              is_user_search: tag.is_user_search,
              selection_is_required: tag.selection_is_required,
              name: tag.name,
              type: new Set<number>(tag.type.map((v) => v.id)),
            })
          }
        })
      })

      // 性别
      if (toJS(this.detail).gender_type_id_arr.length === 0) {
        if (tag_obj.filter((v) => v.name === '性别').length > 0) {
          tag_obj
            .filter((v) => v.name === '性别')[0]
            .type.forEach((v) => this.valid_gender_options.add(v))
        }
      }
      // 年龄
      if (toJS(this.detail).age_type_id_arr.length === 0) {
        if (tag_obj.filter((v) => v.name === '年龄').length > 0) {
          tag_obj
            .filter((v) => v.name === '年龄')[0]
            .type.forEach((v) => this.valid_type_options.add(v))
        }
      }
      // 客户可见区域
      if (toJS(this.detail).special_type_id_arr.length === 0) {
        if (tag_obj.filter((v) => v.name === '客户可见区域').length > 0) {
          tag_obj
            .filter((v) => v.name === '客户可见区域')[0]
            .type.forEach((v) => this.valid_region_options.add(v))
        }
      }
      // 尺码
      if (toJS(this.detail).size_type_id_arr.length === 0) {
        if (tag_obj.filter((v) => v.name === '尺码').length > 0) {
          tag_obj
            .filter((v) => v.name === '尺码')[0]
            .type.forEach((v) => this.valid_clothe_type_options.add(v))
        }
      }
      // 色系
      if (toJS(this.detail).color_type_id_arr.length === 0) {
        if (tag_obj.filter((v) => v.name === '色系').length > 0) {
          /// 去除标准片不具备的tag
          tag_obj
            .filter((v) => v.name === '色系')[0]
            .type.forEach((v) => this.valid_color_type_options.add(v))
        }
      }
      // 风格
      if (toJS(this.detail).style_type_id_arr.length === 0) {
        if (tag_obj.filter((v) => v.name === '风格').length > 0) {
          tag_obj
            .filter((v) => v.name === '风格')[0]
            .type.forEach((v) => this.valid_style_type_options.add(v))
        }
      }
      //// valid_option_ids 这是从接口拿出的所有标签下子标签
      const valid_option_ids = new Set([
        ...Array.from(this.valid_gender_options),
        ...Array.from(this.valid_type_options),
        ...Array.from(this.valid_region_options),
        ...Array.from(this.valid_clothe_type_options),
        ...Array.from(this.valid_color_type_options),
        ...Array.from(this.valid_style_type_options),
      ])

      const new_tag = tag_obj
        .map((value) => ({
          id: value.id,
          name: value.name,
          is_user_search: value.is_user_search,
          selection_is_required: value.selection_is_required,
          types: Array.from(value.type)
            .filter((v) => {
              if (
                value.name === '年龄' ||
                value.name === '性别' ||
                value.name === '客户可见区域' ||
                value.name === '尺码' ||
                value.name === '色系' ||
                value.name === '风格'
              ) {
                return valid_option_ids.has(v)
              } else {
                if (this.valid_clothe_type_options.size === 0) {
                  return true
                } else {
                  return valid_option_ids.has(v)
                }
              }
            })
            .map((v) => type_obj.find((type) => type.id === v)!),
        }))
        .filter((value) => {
          return value.name === '年龄'
            ? value.types.length !== 0
            : value.name === '性别'
            ? value.types.length !== 0
            : value.name === '客户可见区域'
            ? value.types.length !== 0
            : value.name === '尺码'
            ? value.types.length !== 0
            : value.name === '色系'
            ? value.types.length !== 0
            : value.name === '风格'
            ? value.types.length !== 0
            : false
        })

      return new_tag
    } else {
      return []
    }
  }

  @computed private get filterTagData(): EmployeePreorderClotheDetail[] {
    // 更具当前用户选择的tag去作为最基础的筛选数据
    const age_options = this.options.find((value) => value.name === '年龄')
    const gender_options = this.options.find((value) => value.name === '性别')
    const region_options = this.options.find(
      (value) => value.name === '客户可见区域'
    )
    const chima_options = this.options.find((value) => value.name === '尺码')
    const style_options = this.options.find((value) => value.name === '风格')
    const color_options = this.options.find((value) => value.name === '色系')

    const valid_age_dataset: Set<number> = new Set<number>()
    const valid_gender_dataset: Set<number> = new Set<number>()
    const valid_region_dataset: Set<number> = new Set<number>()
    const valid_chima_dataset: Set<number> = new Set<number>()
    const valid_style_dataset: Set<number> = new Set<number>()
    const valid_color_dataset: Set<number> = new Set<number>()

    const valid_age_options: Set<number> = new Set<number>()
    const valid_gender_options: Set<number> = new Set<number>()
    const valid_region_options: Set<number> = new Set<number>()
    const valid_chima_options: Set<number> = new Set<number>()
    const valid_style_options: Set<number> = new Set<number>()
    const valid_color_options: Set<number> = new Set<number>()

    // 判断当前用户选择的tag然后写入到
    let baseTagList: number[] = []
    if (
      this.user_standard_num_tag.length > 0 &&
      this.user_standard_num_tag.length >= this.user_select_index
    ) {
      // 查询出每个标签输入哪一个分类白标签 然后写入
      baseTagList = this.user_standard_num_tag[
        this.user_select_index
      ].standard_tag
        .split(',')
        .map((v) => parseInt(v))
    }

    // 年龄
    if (this.type_selected.size === 0) {
      age_options?.types.forEach((v) => {
        // 再基础标签找找到放进去
        if (baseTagList.indexOf(v.id) > -1) {
          return valid_age_options.add(v.id)
        }
      })
    } else {
      this.type_selected.forEach((v) => valid_age_options.add(v))
    }
    // 客户可见区域
    if (this.region_selected.size === 0) {
      region_options?.types.forEach((v) => {
        // 再基础标签找找到放进去
        if (baseTagList.indexOf(v.id) > -1) {
          return valid_region_options.add(v.id)
        }
      })
    } else {
      this.region_selected.forEach((v) => valid_region_options.add(v))
    }
    // 性别
    if (this.gender_selected.size === 0) {
      gender_options?.types.forEach((v) => {
        // 再基础标签找找到放进去
        if (baseTagList.indexOf(v.id) > -1) {
          return valid_gender_options.add(v.id)
        }
      })
    } else {
      this.gender_selected.forEach((v) => valid_gender_options.add(v))
    }
    // 色系
    if (this.color_type_selected.size === 0) {
      color_options?.types.forEach((v) => {
        if (baseTagList.indexOf(v.id) > -1) {
          return valid_color_options.add(v.id)
        }
      })
    } else {
      this.color_type_selected.forEach((v) => valid_color_options.add(v))
    }
    // 尺码
    if (this.clothe_type_selected.size === 0) {
      chima_options?.types.forEach((v) => {
        if (baseTagList.indexOf(v.id) > -1) {
          return valid_chima_options.add(v.id)
        }
      })
    } else {
      this.clothe_type_selected.forEach((v) => valid_chima_options.add(v))
    }
    // 风格
    if (this.color_style_selected.size === 0) {
      style_options?.types.forEach((v) => {
        if (baseTagList.indexOf(v.id) > -1) {
          return valid_style_options.add(v.id)
        }
      })
    } else {
      this.color_style_selected.forEach((v) => valid_style_options.add(v))
    }

    this.type_clothes?.forEach((value) => {
      // 年龄
      if (valid_age_options.has(value.tag_id)) {
        if (valid_age_options.has(value.tag_id)) {
          value.data_id.forEach((v) => valid_age_dataset.add(v))
        }
      }
      // 性别
      if (valid_gender_options.has(value.tag_id)) {
        if (valid_gender_options.has(value.tag_id)) {
          value.data_id.forEach((v) => valid_gender_dataset.add(v))
        }
      }
      // 用户可见区
      if (valid_region_options.has(value.tag_id)) {
        if (valid_region_options.has(value.tag_id)) {
          value.data_id.forEach((v) => valid_region_dataset.add(v))
        }
      }

      // 尺码 如果是必填 或者 预选但返回不为空
      if (
        chima_options?.selection_is_required === 'yes' ||
        toJS(this.detail.size_type_id_arr).length !== 0
      ) {
        if (valid_chima_options.has(value.tag_id)) {
          if (valid_chima_options.has(value.tag_id)) {
            value.data_id.forEach((v) => valid_chima_dataset.add(v))
          }
        }
      }
      // if (Array.from(this.clothe_type_selected).length > 0) {
      //   if (valid_chima_options.has(value.tag_id)) {
      //     value.data_id.forEach((v) => valid_chima_dataset.add(v))
      //   }
      // }
      // 风格
      if (Array.from(this.color_style_selected).length > 0) {
        if (valid_style_options.has(value.tag_id)) {
          value.data_id.forEach((v) => valid_style_dataset.add(v))
        }
      }
      // 色系
      if (Array.from(this.color_type_selected).length > 0) {
        if (valid_color_options.has(value.tag_id)) {
          value.data_id.forEach((v) => valid_color_dataset.add(v))
        }
      }
    })

    const base_read_handle = []

    if (Array.from(valid_age_dataset).length > 0) {
      base_read_handle.push(Array.from(valid_age_dataset))
    }

    if (Array.from(valid_gender_dataset).length > 0) {
      base_read_handle.push(Array.from(valid_gender_dataset))
    }

    if (Array.from(valid_chima_dataset).length > 0) {
      base_read_handle.push(Array.from(valid_chima_dataset))
    }

    if (Array.from(valid_style_dataset).length > 0) {
      base_read_handle.push(Array.from(valid_style_dataset))
    }

    if (Array.from(valid_color_dataset).length > 0) {
      base_read_handle.push(Array.from(valid_color_dataset))
    }

    if (Array.from(valid_region_dataset).length > 0) {
      base_read_handle.push(Array.from(valid_region_dataset))
    }

    if (base_read_handle.length === 0) return []

    const hand_arr = base_read_handle.reduce((a, b) =>
      a.filter((c) => b.includes(c))
    )

    // 删除锁住
    const pending_processing =
      this._data?.filter((v) => new Set(hand_arr).has(v.id)) || []
    const list: EmployeePreorderClotheDetail[] = []

    for (let i = 0; i < pending_processing.length; i++) {
      const item = pending_processing[i]
      if (this.lockStandardId.indexOf(item.id) === -1) {
        list.push(item)
      }
    }

    return list
  }

  @computed private get filterData(): EmployeePreorderClotheDetail[] {
    const age_options = this.options.find((value) => value.name === '年龄')
    const gender_options = this.options.find((value) => value.name === '性别')
    const region_options = this.options.find(
      (value) => value.name === '客户可见区域'
    )
    const chima_options = this.options.find((value) => value.name === '尺码')
    const style_options = this.options.find((value) => value.name === '风格')
    const color_options = this.options.find((value) => value.name === '色系')

    const valid_age_dataset: Set<number> = new Set<number>()
    const valid_gender_dataset: Set<number> = new Set<number>()
    const valid_region_dataset: Set<number> = new Set<number>()
    const valid_chima_dataset: Set<number> = new Set<number>()
    const valid_style_dataset: Set<number> = new Set<number>()
    const valid_color_dataset: Set<number> = new Set<number>()

    // if (age_options && gender_options) { //  && chima_options && style_options && color_options
    // } else {
    //   return [];
    // }

    const valid_age_options: Set<number> = new Set<number>()
    const valid_gender_options: Set<number> = new Set<number>()
    const valid_region_options: Set<number> = new Set<number>()
    const valid_chima_options: Set<number> = new Set<number>()
    const valid_style_options: Set<number> = new Set<number>()
    const valid_color_options: Set<number> = new Set<number>()

    // 年龄
    if (this.type_selected.size === 0) {
      age_options?.types.forEach((v) => valid_age_options.add(v.id))
    } else {
      this.type_selected.forEach((v) => valid_age_options.add(v))
    }
    // 客户可见区域
    if (this.region_selected.size === 0) {
      region_options?.types.forEach((v) => valid_region_options.add(v.id))
    } else {
      this.region_selected.forEach((v) => valid_region_options.add(v))
    }
    // 性别
    if (this.gender_selected.size === 0) {
      gender_options?.types.forEach((v) => valid_gender_options.add(v.id))
    } else {
      this.gender_selected.forEach((v) => valid_gender_options.add(v))
    }
    // 色系
    if (this.color_type_selected.size === 0) {
      color_options?.types.forEach((v) => valid_color_options.add(v.id))
    } else {
      this.color_type_selected.forEach((v) => valid_color_options.add(v))
    }
    // 尺码
    if (this.clothe_type_selected.size === 0) {
      chima_options?.types.forEach((v) => valid_chima_options.add(v.id))
    } else {
      this.clothe_type_selected.forEach((v) => valid_chima_options.add(v))
    }
    // 风格
    if (this.color_style_selected.size === 0) {
      style_options?.types.forEach((v) => valid_style_options.add(v.id))
    } else {
      this.color_style_selected.forEach((v) => valid_style_options.add(v))
    }

    this.type_clothes?.forEach((value) => {
      // 年龄
      if (valid_age_options.has(value.tag_id)) {
        if (valid_age_options.has(value.tag_id)) {
          value.data_id.forEach((v) => valid_age_dataset.add(v))
        }
      }
      // 性别
      if (valid_gender_options.has(value.tag_id)) {
        if (valid_gender_options.has(value.tag_id)) {
          value.data_id.forEach((v) => valid_gender_dataset.add(v))
        }
      }
      // 用户可见区
      if (valid_region_options.has(value.tag_id)) {
        if (valid_region_options.has(value.tag_id)) {
          value.data_id.forEach((v) => valid_region_dataset.add(v))
        }
      }

      // 尺码 如果是必填 或者 预选但返回不为空
      if (
        chima_options?.selection_is_required === 'yes' ||
        toJS(this.detail.size_type_id_arr).length !== 0
      ) {
        if (valid_chima_options.has(value.tag_id)) {
          if (valid_chima_options.has(value.tag_id)) {
            value.data_id.forEach((v) => valid_chima_dataset.add(v))
          }
        }
      }
      // if (Array.from(this.clothe_type_selected).length > 0) {
      //   if (valid_chima_options.has(value.tag_id)) {
      //     value.data_id.forEach((v) => valid_chima_dataset.add(v))
      //   }
      // }
      // 风格
      if (Array.from(this.color_style_selected).length > 0) {
        if (valid_style_options.has(value.tag_id)) {
          value.data_id.forEach((v) => valid_style_dataset.add(v))
        }
      }
      // 色系
      if (Array.from(this.color_type_selected).length > 0) {
        if (valid_color_options.has(value.tag_id)) {
          value.data_id.forEach((v) => valid_color_dataset.add(v))
        }
      }
    })

    const base_read_handle = []

    if (Array.from(valid_age_dataset).length > 0) {
      base_read_handle.push(Array.from(valid_age_dataset))
    }

    if (Array.from(valid_gender_dataset).length > 0) {
      base_read_handle.push(Array.from(valid_gender_dataset))
    }

    if (Array.from(valid_chima_dataset).length > 0) {
      base_read_handle.push(Array.from(valid_chima_dataset))
    }

    if (Array.from(valid_style_dataset).length > 0) {
      base_read_handle.push(Array.from(valid_style_dataset))
    }

    if (Array.from(valid_color_dataset).length > 0) {
      base_read_handle.push(Array.from(valid_color_dataset))
    }

    if (Array.from(valid_region_dataset).length > 0) {
      base_read_handle.push(Array.from(valid_region_dataset))
    }

    if (base_read_handle.length === 0) return []

    const hand_arr = base_read_handle.reduce((a, b) =>
      a.filter((c) => b.includes(c))
    )

    return this._data?.filter((v) => new Set(hand_arr).has(v.id)) || []
  }

  @computed get data(): EmployeePreorderClotheDetail[] {
    return this.filterTagData.slice(0, this.current_displayed)
  }
  // @computed get data(): EmployeePreorderClotheDetail[] {
  //   return this.filterData.slice(0, this.current_displayed)
  // }
  constructor() {
    makeAutoObservable(this)
  }
  @computed get isEmpty(): boolean {
    return this._data === null
  }
  loadMore = () => {
    console.log('=======================')
    console.log('触发分页')
    console.log('=======================')
    this.current_displayed += 14
  }
  async customerGetPreorderClotheData(
    store_id: string | null,
    selection_id: number
  ): Promise<any> {
    return new Promise((resolve) => {
      this.loading = true
      requests
        .CustomerGetPreorderClothe(store_id, selection_id)
        .subscribe((value) => {
          runInAction(() => {
            this._data = value
            this.__data = value
            this.loading = false
            resolve(null)
          })
        })
    })
  }

  clear_options() {
    this.current_displayed = 14
    this.type_selected.clear()
    this.gender_selected.clear()
    this.region_selected.clear()
    this.clothe_type_selected.clear()
    this.color_style_selected.clear()
    this.color_type_selected.clear()
  }

  handleGetDetail() {
    return new Promise((r, j) => {
      const value = this.preorder_id
      const stoe_id =
        localStorage.getItem('store_id') || store.storeInfoStore.value
      let url = ''
      if (!stoe_id) {
        url = `/wap/clothing/getUserSelectionDetail?token=${store.userStore.token}&selection_id=${value}`
      } else {
        url = `/wap/clothing/getUserSelectionDetail?token=${store.userStore.token}&selection_id=${value}&store_id=${stoe_id}`
      }
      this.loading = true
      BangBangTangRequest<EmployeePreorderClotheListDataInterface>({
        url,
        data: {},
        method: 'GET',
      }).subscribe((res) => r(res))
    })
  }

  async submit(isSkip = false) {
    if (!this.submit_loading) {
      // 判断是否重复选择
      this.submit_loading = true
      const t: any = await this.handleGetDetail()
      this.submit_loading = false
      if (t.lockStandardId) {
        const d = t.lockStandardId
        const user_select_id_list: any = Array.from(
          store.customerPreorderClotheStore.user_standard_num_tag[
            this.user_select_index
          ].user_select_ids
        )
        // const d = [631, 630]
        console.log('服务器', d)
        console.log(
          '用户自己选的',
          user_select_id_list,
          Array.from(this.selected_photos),
          Array.from(this.selected_photos_repeat)
        )
        let isRepeat = false
        let brxczdefzid = [] // 别人选中的服装id
        for (let i = 0; i < user_select_id_list.length; i++) {
          const user_select_id = user_select_id_list[i]
          if (d.indexOf(user_select_id) > -1) {
            console.log('存在相同的id', user_select_id)
            // 删除用户选中的
            // store.customerPreorderClotheStore.toggle_selected_photo(
            //   user_select_id
            // )
            store.customerPreorderClotheStore.user_images_id_close_clothe(
              this.user_select_index,
              user_select_id
            )
            // 删除筛选中的
            this.selected_photos_repeat.push(user_select_id)
            brxczdefzid.push(user_select_id)
            isRepeat = true
          }
        }
        if (isRepeat) {
          const modal = Modal.alert({
            title: '确认信息',
            content: `有${brxczdefzid.length}套服装已被她人选中，请重新选择`,
            confirmText: '确定',
            onOk: async () => {},
          })
          this.submit_loading = false
          return
        }
      }
      // 正常提交逻辑
      this.submit_loading = true
      if (this.preorder_id) {
        // 修改
        // Array.from(this.selected_photos).forEach((value, index, array) => {
        //   if (index === array.length - 1) {
        //     result += value.toString()
        //   } else {
        //     result += value.toString() + ','
        //   }
        // })
        const result = store.customerPreorderClotheStore.user_standard_num_tag.map(
          (v) => {
            return {
              standard_num: v.standard_num,
              standard_tag: v.standard_tag,
              standard_text: v.standard_text,
              standard_ids: v.user_select_ids.join(','),
            }
          }
        )
        // 判断是否没有东西就提交
        const size_null_detection = result.filter((v) => v.standard_ids)
        if (size_null_detection.length === 0) {
          this.submit_loading = false
          Toast.show('您没有选择任何一套服装')
          return
        }

        // 判断是否过度提交 / 当用户选择大于当前标签数量时
        const size_detection = result.filter(
          (v) => parseInt(v.standard_num) < v.standard_ids.split(',').length
        )
        if (size_detection.length > 0) {
          Modal.alert({
            title: '提示',
            content: `您提交的服装标签数量不正确, 请重新选择`,
            onConfirm: () => {
              this.submit_loading = false
            },
          })
          return
        }

        BangBangTangRequest({
          url: '/wap/clothing/userSelectStandard',
          method: 'POST',
          data: {
            token: store.userStore.token,
            // standard_ids: result,
            standard_data: JSON.stringify(result),
            selection_id: this.preorder_id,
          },
        }).subscribe(() => {
          this.submit_loading = false
          this.toggle_photo_modal_visible(false)
          Toast.show('保存成功')

          if (isSkip) {
            store.customerPreorderClotheListStore.reset()
            const host = store.websiteRuleStore.orginal_domain
            if (host) {
              location.href = `https://${host}/build?token=${localStorage.getItem(
                'token'
              )}`
            } else {
              store.routerStore.replace('/build')
            }
          }
        })
        this.submit_loading = false
      }
    }
  }

  // 清除不必要的信息,一般用于页面退出时使用
  clearOtherData() {
    this.customer_name = ''
    this.preorder_id = null
    this.mobile = ''
    this.limit = ''
    this.share_modal_visible = false
    this.photo_modal_visible = false
    this.popup_visible = false

    this.valid_gender_options = new Set<number>()
    this.valid_type_options = new Set<number>()
    this.valid_region_options = new Set<number>()
    this.valid_clothe_type_options = new Set<number>()
    this.valid_color_type_options = new Set<number>()
    this.valid_style_type_options = new Set<number>()
    // this.selected_photos.clear();
    console.log('清空了')
  }
  toggle_share_modal(value: boolean) {
    this.share_modal_visible = value
  }

  cancel_preorder() {
    Modal.confirm({
      title: '警告',
      content: '是否确定取消预选单',
      onOk: () => {
        this.loading = true
        return new Promise((resolve) => {
          BangBangTangRequest({
            url: '/wap/clothing/userCancelSelection',
            data: {
              token: store.userStore.token,
              selection_id: this.preorder_id,
            },
            method: 'POST',
          })
            .pipe(finalize(() => runInAction(() => (this.loading = false))))
            .subscribe((value) => {
              alert('取消成功')
              resolve(true)
              store.routerStore.replace('/build')
            })
        })
      },
    })
  }

  toggle_selected_photo(id: number) {
    if (false) {
      // this.selected_photos.has(id)
      this.remove_selected_photo(id)
    } else {
      const item = this.user_standard_num_tag[this.user_select_index]
      if (item.user_select_ids.length < parseInt(item.standard_num, 10) || 0) {
        // this.add_selected_photo(id)
        if (item.user_select_ids.indexOf(id) === -1) {
          this.user_standard_num_tag[
            this.user_select_index
          ].user_select_ids.push(id)
          // 判断是否选择满
          if (
            store.customerPreorderClotheStore.userClothesSize ===
            store.customerPreorderClotheStore.userClothesOptionalSize
          ) {
            // store.customerPreorderClotheStore.toggle_photo_modal_status(true)
            const modal = Modal.confirm({
              title: '提示',
              content: '您的服装套数已选满是否提交?',
              onCancel: () => {
                console.log('点击cancel')
              },
              onOk: () => {
                console.log('点击ok')
                this.submit()
              },
            })
          }
        }
      } else {
        Toast.show(`此组标签您最多只能选择${item.standard_num}张照片`)
        this.photo_modal_visible = true
      }
    }
  }
}
