import React from 'react'
import { store } from '../../store/store.root'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { format } from 'date-fns'
import { Modal, Toast } from 'zarm'
import { toJS } from 'mobx'

@observer
export class CustomerClotheButtonRow extends React.Component {
  render() {
    if (store.customerPreorderClotheStore.preorder_id) {
      return (
        <div className="customer-preorder-clothe-button-row">
          <div
            className="label"
            onClick={() =>
              // store.customerPreorderClotheStore.toggle_photo_modal_visible(true)
              store.customerPreorderClotheStore.toggle_photo_modal_status(true)
            }
          >
            查看 已选{store.customerPreorderClotheStore.userClothesSize}
            /可选
            {store.customerPreorderClotheStore.userClothesOptionalSize}
          </div>
          <div
            style={{
              color: '#fff',
              letterSpacing: 12,
            }}
            className={classNames({
              label: true,
              submit: true,
              enabled:
                store.customerPreorderClotheStore.selected_photos.size > 0,
            })}
            onClick={() => {
              const limit = store.customerPreorderClotheStore.user_standard_num_tag
                .map((v) => v.user_select_ids)
                .map((v) => v.length)
                .reduce(
                  (accumulator, currentValue) => accumulator + currentValue,
                  0
                )

              const total = store.customerPreorderClotheStore.user_standard_num_tag
                .map((v) => parseInt(v.standard_num))
                .reduce(
                  (accumulator, currentValue) => accumulator + currentValue,
                  0
                ) // 必须要选完

              if (
                store.customerPreorderClotheStore.detail.schedule_type_data
                  .submit_selection_status === 'must'
              ) {
                if (total != limit) {
                  Toast.show(`您还可选择 ${total - limit} 套， 请继续选择`)
                } else {
                  store.customerPreorderClotheStore.submit()
                }
              } else {
                if (total != limit) {
                  const modal = Modal.confirm({
                    title: '确认信息',
                    content: `您还可选 ${total - limit} 套， 是否提交？`,
                    confirmText: '提交',
                    onCancel: () => {},
                    onOk: async () => {
                      store.customerPreorderClotheStore.submit()
                    },
                  })
                } else {
                  store.customerPreorderClotheStore.submit()
                }
              }
            }}
          >
            提交
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}
